import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeading"

import styled from "styled-components"

const VideoContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;

  iframe {
    margin-bottom: 2rem;
    width: 560px;
    height: 315px;
    @media (max-width: 615px) {
      width: 95%;
      height: 315px;
    }
  }

  @media (max-width: 1460px) {
    grid-template-columns: 100%;
  }
`

const VideoPage = () => {
  return (
    <MainLayout>
      <PageHeading title="Videos" subHeading="Timberline" />
      <VideoContainer>
        <iframe
          src="https://www.youtube.com/embed/Nz6X7CRPwdw"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowFullScreen"
        ></iframe>
        <iframe
          src="https://www.youtube.com/embed/lY-KLRssLJQ"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowFullScreen"
        ></iframe>
        {/* <iframe
          src="https://www.youtube.com/embed/c-HKLJy1itI"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowFullScreen"
        ></iframe> */}
        <iframe
          src="https://www.youtube.com/embed/JhwOrj0S84E"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowFullScreen"
        ></iframe>
        <iframe
          src="https://youtube.com/embed/iGmRNmnjOmo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowFullScreen"
        ></iframe>
      </VideoContainer>
    </MainLayout>
  )
}

export default VideoPage
